import { useEffect, useState } from "react";

// material-ui
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,

  MenuItem,

  Select,
  SelectChangeEvent,
  Stack,

  TextField,
  Tooltip,
  Autocomplete,

} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
import { DeleteFilled } from "@ant-design/icons";
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import AlertCompanyReportDelete from "./AlertCompanyReportDelete";
import { CompanyReportModel } from "model/companyList/companyReport";
import {
  addCompanyReport,
  editCompanyReport,
} from "store/actions/main/companyList/companyReport";
import { getAllBasicInfo } from "store/actions/main/cv/basicinfo/basicInfo";
import { getAllCompanyProfile } from "store/actions/main/companyProfile";
import { getAllReport } from "store/actions/main/basicInfo/reporet";


// constant
const getInitialValues = (customer: CompanyReportModel | null) => {
  const newCustomer = {
    resume_id: "",
    report_candidate_id: "",
    company_profile_id: "",
  };

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCompanyReportAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: CompanyReportModel | null| any;
  closeModal: () => void;
}) => {
2
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CustomerSchema = Yup.object().shape({
    resume_id: Yup.string().required("Resume is required"),
    company_profile_id: Yup.string().required("Company Profile is required"),
    report_candidate_id: Yup.string().required("Report Candidate is required"),
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const [selectedResume, setSelectedREsume] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedReport, setSelectedReport] = useState([]);
  const fetchDataPackages = () => {
    dispatch(getAllBasicInfo({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setSelectedREsume(data);
        // Set default selected country to the first one in the list
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchCompanyProfile = () => {
    dispatch(getAllCompanyProfile({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setSelectedCompany(data);
        // Set default selected country to the first one in the list
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchReport = () => {
    dispatch(getAllReport({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setSelectedReport(data);
        // Set default selected country to the first one in the list
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchDataPackages();
    fetchCompanyProfile();
    fetchReport();
  }, [dispatch]);
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editCompanyReport({
              id: customer.id || 0,
              data: values,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "companyList/editCompanyReport/fulfilled") {
              openSnackbar({
                open: true,
                message: "Company Report updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addCompanyReport(values)).then((action) => {
            fetchData();
            if (action.type === "companyList/addCompanyReport/fulfilled") {
              openSnackbar({
                open: true,
                message: "Company Report added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
 
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Company Report" : "New Company Report"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_name">
                          Resume
                        </InputLabel>
                        <Select
                          id="customer-user_name"
                          displayEmpty
                          {...getFieldProps("resume_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = selectedResume.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("resume_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("resume_id").value || ""}
                        >
                          {selectedResume &&
                            selectedResume.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-company_profile_id">
                          Company Name
                        </InputLabel>
                        <Autocomplete
                          id="customer-company_profile_id"
                          options={selectedCompany}
                          getOptionLabel={(option) => option.user_name || ""}
                          onChange={(event, newValue:any) => {
                            const selectedId = newValue ? newValue.id : "";
                            const selectedVacancyName = newValue
                              ? newValue.user_name
                              : "";
                            setFieldValue("to_user_id", selectedId);
                            setFieldValue("user_name", selectedVacancyName); // Set user name value to another field
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...getFieldProps("to_user_id")}
                            />
                          )}
                          value={
                            selectedCompany.find(
                              (user) =>
                                user.id ===
                                getFieldProps("company_profile_id").value,
                            ) || null
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-report_candidate_id">
                          Report Candidate
                        </InputLabel>
                        <Select
                          id="customer-report_candidate_id"
                          displayEmpty
                          {...getFieldProps("report_candidate_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = selectedCompany.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.en_text
                              : "";
                            setFieldValue("report_candidate_id", selectedId);
                            setFieldValue("en_text", selectedUserName); // Set user name value to another field
                          }}
                          value={
                            getFieldProps("report_candidate_id").value || ""
                          }
                        >
                          {selectedReport &&
                            selectedReport.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.en_text}
                              </MenuItem>
                            ))}
                        </Select>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? <FormattedMessage id="edit" /> : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCompanyReportDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.user_name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormCompanyReportAdd;
