// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";

import { ReplyApplicant } from "model/companyList/replyApp";
import {
  addReplayApplication,
  editReplyApplication,
} from "store/actions/main/companyList/replyApp";
import { FormattedMessage } from "react-intl";

// constant
const getInitialValues = (customer: ReplyApplicant | null) => {
  if (customer) {
    return {
      en_message: customer.en_message || "",
      ar_message: customer.ar_message || "",
      en_title: customer.en_title || "",
      ar_title: customer.ar_title || "",
    };
  } else {
    return {
      en_message: "",
      ar_message: "",
      ar_title: "",
      en_title: "",
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormReplyAppAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
  setLoading,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: ReplyApplicant | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    en_message: Yup.string().required("English message is required"),
    ar_message: Yup.string().required("Arabic message is required"),
    en_title: Yup.string().required("English title is required"),
    ar_title: Yup.string().required("Arabic title is required"),
  });

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editReplyApplication({
              id: customer.id || 0,
              data: {
                en_message: values.en_message,
                en_title:values.en_title,
                ar_title: {
                ar:values.ar_title
                },
                ar_message: {
                  ar:values.ar_message
                  }
            }}),
          ).then((action) => {
            fetchData();
            if (action.type === "companyList/editReplyApplication/fulfilled") {
              openSnackbar({
                open: true,
                message: "Reply application edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addReplayApplication({en_message: values.en_message,
            en_title:values.en_title,
            ar_title: {
            ar:values.ar_title
            },
            ar_message: {
              ar:values.ar_message
              }})).then((action) => {
            fetchData();
            if (action.type === "companyList/addReplayApplication/fulfilled") {
              openSnackbar({
                open: true,
                message: "Reply application added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? <FormattedMessage id="edit-reply-application" /> : <FormattedMessage id="new-reply-application" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                  <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-en_title">
                        <FormattedMessage id="english-title" /><span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-english-title" />}>
                                
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-en_title"
                          placeholder="Enter English Title"
                          {...getFieldProps("en_title")}
                          error={Boolean(touched.en_title && errors.en_title)}
                          helperText={touched.en_title && errors.en_title}
                        />
                      </Stack>
                  </Grid>
                  <Grid md={1}/>
                  <Grid item xs={12} sm={5} mb={1}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer-ar_title">
                      <FormattedMessage id="arabic-title" /><span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-arabic-title" />}>
                             
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="customer-ar_title"
                        placeholder="Enter Arabic Title"
                        {...getFieldProps("ar_title")}
                        error={Boolean(touched.ar_title && errors.ar_title)}
                        helperText={touched.ar_title && errors.ar_title}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer-en_message">
                      <FormattedMessage id="english-message" /> <span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-english-message" />}>
                                
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="customer-en_message"
                        placeholder="Enter English Message"
                        {...getFieldProps("en_message")}
                        error={Boolean(
                          touched.en_message && errors.en_message,
                        )}
                        helperText={touched.en_message && errors.en_message}
                        multiline
                        rows={3}
                      />
                    </Stack>
                  </Grid>
                  <Grid md={1}/>
                  <Grid item xs={12} sm={5}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer-ar_message">
                      <FormattedMessage id="arabic-message" /> <span style={{color:'red'}}>*</span>
                              <span style={{color:'grey', marginLeft:'5px'}}>
                                <Tooltip title={<FormattedMessage id="type-arabic-message" />}>
                                  <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                                </Tooltip>
                              </span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="customer-ar_message"
                        placeholder="Enter Arabic Message"
                        {...getFieldProps("ar_message")}
                        error={Boolean(
                          touched.ar_message && errors.ar_message,
                        )}
                        helperText={touched.ar_message && errors.ar_message}
                        multiline
                        rows={3}
                      />
                    </Stack>
                  </Grid>  
              </Grid>                
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="submit" />}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormReplyAppAdd;
