const GET_CATEGORIES = "category";

const CATEGORIES = "category/";

const MULTI_DELET = "category/remove/bulk_delete";

const SEARCH_CATEGORIES = "category/category/search/";

const GET_PARENT_CAT = "category/category/parents";

const CHILD_CAT = "category/category/childs/";

export default {GET_CATEGORIES, MULTI_DELET, SEARCH_CATEGORIES, CATEGORIES, GET_PARENT_CAT, CHILD_CAT };
