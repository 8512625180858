import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";
import LanguageIcon from "@mui/icons-material/Language";

import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { FormattedMessage } from "react-intl";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TitleIcon from "@mui/icons-material/Title";
import CommentIcon from "@mui/icons-material/Comment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";

import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
const useBasicInfoItems = () => {
  const icons = {
    TypeSpecimenIcon,
    LanguageIcon,
    SchoolIcon,
    AccountBalanceIcon,
    StarBorderIcon,
    CardGiftcardIcon,
    HowToRegIcon,
    WhereToVoteIcon,
    MarkChatUnreadIcon,
    HelpOutlineIcon,
    AttachMoneyIcon,
    TitleIcon,
    CategoryOutlinedIcon,
    LocationCityOutlinedIcon,
    CommentIcon,
    AssessmentIcon,
  };

  const basicInfoItems = {
    id: "group-basicInfo",
    title: (
      <FormattedMessage id="site-parameters">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [{
        id: "categories",
        title: <FormattedMessage id="categories" />,
        type: "item",
        url: "/categories",
        icon: icons.CategoryOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "countries",
        title: <FormattedMessage id="countries" />,
        type: "item",
        url: "/countries",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
      ,
      {
        id: "job-types",
        title: <FormattedMessage id="jobtypes" />,
        type: "item",
        url: "/jobtypes",
        icon: icons.TypeSpecimenIcon,
        breadcrumbs: false,
      },
      {
        id: "job-avarege",
        title: <FormattedMessage id="jobavailabilities" />,
        type: "item",
        url: "/jobavailabilities",
        icon: icons.HowToRegIcon,
        breadcrumbs: false,
      },
      {
        id: "degree",
        title: <FormattedMessage id="degreetypes" />,
        type: "item",
        url: "/degree",
        icon: icons.StarBorderIcon,
        breadcrumbs: false,
      },

      {
        id: "educational",
        title: <FormattedMessage id="educationalinstitutions" />,
        type: "item",
        url: "/educational",
        icon: icons.SchoolIcon,
        breadcrumbs: false,
      },

      {
        id: "language",
        title: <FormattedMessage id="language" />,
        type: "item",
        url: "/language",
        icon: icons.LanguageIcon,
        breadcrumbs: false,
      },
      {
        id: "skills",
        title: <FormattedMessage id="skills" />,
        type: "item",
        url: "/skills",
        icon: icons.CardGiftcardIcon,
        breadcrumbs: false,
      },
      {
        id: "bannerplace",
        title: <FormattedMessage id="bannerplace" />,
        type: "item",
        url: "/bannerplace",

        icon: icons.WhereToVoteIcon,
        breadcrumbs: false,
      },
      {
        id: "question",
        title: <FormattedMessage id="question" />,
        type: "item",
        url: "/question",

        icon: icons.HelpOutlineIcon,
        breadcrumbs: false,
      },
      {
        id: "currency",
        title: <FormattedMessage id="currency" />,
        type: "item",
        url: "/currency",

        icon: icons.AttachMoneyIcon,
        breadcrumbs: false,
      },
      {
        id: "titles",
        title: <FormattedMessage id="titles" />,
        type: "item",
        url: "/titles",
        icon: icons.TitleIcon,
        breadcrumbs: false,
      },
      {
        id: "bank",
        title: <FormattedMessage id="bank" />,
        type: "item",
        url: "/bank",
        icon: icons.AccountBalanceIcon,
        breadcrumbs: false,
      },
      {
        id: "comment-action",
        title: <FormattedMessage id="comment-action" />,
        type: "item",
        url: "/comment-action",
        icon: icons.CommentIcon,
        breadcrumbs: false,
      },
      {
        id: "report-candidate",
        title: <FormattedMessage id="report-candidate" />,
        type: "item",
        url: "/report-candidate",
        icon: icons.AssessmentIcon,
        breadcrumbs: false,
      },
      {
        id: "replytoapplicant",
        title: <FormattedMessage id="reply-to-candidate" />,
        type: "item",
        url: "/reply-to-applicant",
        icon: icons.MarkChatUnreadIcon,
        breadcrumbs: false,
      },
      {
        id: "mail-templates",
        title: <FormattedMessage id="mail-templates" />,
        type: "item",
        url: "/mail-templates",
        icon: icons.AssessmentIcon,
        breadcrumbs: false,
      },
      {
        id: "admin-resume-note-type",
        title: <FormattedMessage id="admin-resume-note-type" />,
        type: "item",
        url: "/admin-resume-note-type",
        icon: icons.AssessmentIcon,
        breadcrumbs: false,
      },
      {
        id: "translations",
        title: <FormattedMessage id="translations" />,
        type: "item",
        url: "/translations",
        icon: icons.AssessmentIcon,
        breadcrumbs: false,
      },
    ],
  };

  return basicInfoItems;
};

export default useBasicInfoItems;
