import { useEffect, useState } from "react";

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _  from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets

// types
import { SnackbarProps } from "types/snackbar";
import { CustomerList } from "types/customer";
import { useAppDispatch } from "hooks";
import {
  addNewCategory,
  editOneCategory,
  getAllCategories,
} from "store/actions/main/categories";
import { ParentCat } from "model/categories";
import { CircularProgress } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { FormattedMessage } from "react-intl";



// constant
const getInitialValues = (customer: CustomerList | null) => {
  if (customer) {
    return {
      name: customer.name || "",
      name_trans: customer.name_trans.ar || "",
      is_active: customer.is_active || false,
      order: customer.order || "",
    };
  }
else{
  return {
    parent_id: "",
    name: "",
    order: "",
    is_active: false,
    name_trans:""
  }
  
}


};
// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCustomerAdd = ({
  customer,
  id,
  loading,
  fetchData,
  setLoading,
  closeModal,
}: {
  loading: any;
  id: number;
  setLoading: any;
  fetchData: any;
  customer: CustomerList | null;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();

  const [categories, setCategories] = useState<ParentCat[]>([]);
  const [selectedParentCat, setSelectedParentCat] = useState<ParentCat | null>(
    null,
  );
console.log(customer,"customer")
  const fetchCatData = (url?: string) => {
    dispatch(getAllCategories({pageNumber:1}))
      .then((action) => {
        const { data } = action.payload;
        const fetchedUserData: ParentCat[] = data;
        const rowsWithKeys = fetchedUserData.map((user) => ({
          ...user,
          key: user.id,
        }));
        setLoading(false);
        setCategories(rowsWithKeys);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchCatData();
  }, [ dispatch ]);
  useEffect(() => {
    setLoading(false);

    // Initialize selectedParentCountryId and selectedParentCountry if customer exists
    if (customer && customer.parent_id) {
      const selectedCat = categories.find(
        (country) => country.name === customer.parent_id,
      );
      if (selectedCat) {
        setSelectedParentCat(selectedCat);
        setSelectedParentCategoryId(selectedCat.name);
      }
    }
  }, [dispatch, id, customer, categories]);

  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState<
    string | null
  >(null);

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().required("English name is required"),
    name_trans: Yup.string().required('Arabic name is required'),
    order: Yup.number().required("Order is required"),
  });

  const handleChangeParentName = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const selectedCat = categories.find((cat) => cat.name === selectedValue);
    setSelectedParentCategoryId(event.target.value);
    setSelectedParentCat(selectedCat); // Update selected parent country state
  };
  // const handleNestedChange = (event) => {
  //   const { name, value } = event.target;
  //   setFieldValue(name, value);
  // };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneCategory({
              categoryId: customer.id || 0,
              data: {
                parent_id: selectedParentCat.id,
                name: values.name,
                order: values.order,
                is_active: values.is_active,
                name_trans: values.name_trans,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "category/editOneCategory/fulfilled") {
              openSnackbar({
                open: true,
                message: "Category edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewCategory({
              parent_id: selectedParentCategoryId,
              name: values.name,
              order: values.order,
              is_active: values.is_active,
              name_trans: values.name_trans,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "category/addNewCategory/fulfilled") {
              openSnackbar({
                open: true,
                message: "Category added successfully!",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });
  
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? <FormattedMessage id="edit-category-details" /> : <FormattedMessage id="edit-category-details" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="english-name" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-english-name" /> }>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder="Enter english name"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name">{<FormattedMessage id="arabic-name" /> } <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-arabic-name" /> }>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                    fullWidth
                      id="customer-name"
                      name="name_trans"
                      {...getFieldProps("name_trans")}
                      placeholder="Enter Arabic Name"
                      error={Boolean(touched.name_trans && errors.name_trans)}
                      helperText={touched.name_trans && errors.name_trans}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} mb={2}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-parent_name">
                        <FormattedMessage id="parent-name" /><span style={{ color: "grey" }}>{` (Optional)`}</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={<FormattedMessage id="add-parent-name" />}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-parent_name"
                          displayEmpty
                          value={
                            selectedParentCat
                              ? selectedParentCat.name
                              : customer?.parent_id
                          }
                          onChange={handleChangeParentName}
                       
                          input={
                            <OutlinedInput
                              id="select-column-hiding"
                              placeholder="Sort by"
                            />
                          }
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <Typography variant="subtitle1"></Typography>
                              );
                            }
                            const cat = categories.find(
                              (item) => item.name === selected,
                            );
                            return (
                              <Typography variant="subtitle2">
                                {cat ? cat.name : ""}
                              </Typography>
                            );
                          }}
                        >
                          {categories.map((column: any) => (
                            <MenuItem key={column.id} value={column.name}>
                              <ListItemText primary={column.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} sm={5}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-order"><FormattedMessage id="order" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="type-order-number" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type="number"
                      id="customer-order"
                      placeholder="Enter Order"
                      {...getFieldProps("order")}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                    <FormattedMessage id="status" />
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="set-the-country-status" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={formik.values.is_active}
                            {...getFieldProps("is_active")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("is_active", checked);
                            }}
                          />
                        }
                        label={formik.values.is_active ? <FormattedMessage id="active" /> : <FormattedMessage id="inactive" />}
                      />
                    </FormControl>
                    {touched.is_active && errors.is_active && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.is_active}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> :<FormattedMessage id="submit" />}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormCustomerAdd;
