// material-ui
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

// project import
import Avatar from "components/@extended/Avatar";
import { PopupTransition } from "components/@extended/Transitions";

//import { deleteCustomer } from 'api/customer';
import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import { deleteMajorCompany } from "store/actions/main/ads/majorCompany";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  id: number;
  title: any;
  open?: boolean;
  handleClose?: () => void;
  fetchData?: any;
}

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function AlertBestPlaceDelete({
  id,
  title,
  open,
  handleClose,
  fetchData,
}: Props) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDelete = () => {
    setIsLoading(true)
    dispatch(deleteMajorCompany({ id: id })).then((action) => {
      setIsLoading(false)
      fetchData();
      if (action.type === "ads/deleteMajorCompany/fulfilled") {
        openSnackbar({
          open: true,
          message: "Major Company deleted successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        handleClose();
      }
    });
  };
  console.log(id);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar
            color="error"
            sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
          >
            <DeleteFilled />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              <FormattedMessage id="are-you-sure-you-want-to-delete" />
            </Typography>
            <Typography align="center">
              <FormattedMessage id="by-deleting" />
              <Typography variant="subtitle1" component="span">
                {" "}
                "{title}"{" "}
              </Typography>
              all task assigned to that user will also be deleted.
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button
              fullWidth
              onClick={handleClose}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={handleDelete}
              autoFocus
            >
              {isLoading? <CircularProgress size="1rem" color="inherit"/> : "Delete"}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
