import { useState,useEffect} from "react";

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,

  InputLabel,

  Stack,
  
  TextField,
  Tooltip,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";

import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";


import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";
import Checkbox from "@mui/material/Checkbox";

import { useTheme } from '@mui/material/styles';

import { RolesModel } from "model/roles";
import { addNewRoles, editRole, getOneRole, getPermission } from "store/actions/main/roles";
import MainCard from "components/MainCard";
import { ThemeDirection } from "types/config";
import useConfig from "hooks/useConfig";
import { FormattedMessage } from "react-intl";

// constant
const getInitialValues = (customer: RolesModel | null) => {
  const newCustomer = {
    name: "",
    permissions: [],
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormRolesManagmentEdit = ({
  customer,
  id,
  fetchData,
 
  loading,
  closeModal,
  setLoading,
}: {
  fetchData?: any;
 
  loading?: any;
  id?: number;
  setLoading?: any;
  customer?: RolesModel | null;
  closeModal?: () => void;
}) => {
    const [permissions, setPermissions] = useState<any>([]);
  const dispatch = useAppDispatch();
  const pathname = window.location.pathname;
  console.log(pathname,"pathname")
  const segments = pathname.split("/");
  console.log(segments,"segments")
  const roleId = segments[segments?.length - 1];
  console.log(roleId,"roleid")
  const path=segments[1]
  console.log(path,"path")

 // @ts-ignore
  const [openAlert, setOpenAlert] = useState(false);
const [permissionsArray, setpermissionsArray] = useState([]);
const [editedPermissionArray, seteditedPermissionArray] = useState({name:'',permissions:[]});
const [endResults, setendResults] = useState({name:'',permissions:[]});
const [rolesData, setrolesData] = useState<any>();
const [roleName, setroleName] = useState("");
const {  themeDirection } = useConfig();
console.log(themeDirection,"themedirection")
useEffect(() => {
   if (path!=='add-role') {
  dispatch(getOneRole({id:roleId})).then((action)=>{
    const data = action.payload;
    console.log(data?.data?.permissions,"data 1");
    setrolesData(data.data)
   editedPermissionArray.name=data.data.name;
  
   setroleName(data.data.name)
    editedPermissionArray.permissions=data?.data?.permissions;
    seteditedPermissionArray((prevState) => ({
      ...prevState,
      permissions: prevState.permissions.map(permission => {
        
          const updatedPermission = { ...permission };
          delete updatedPermission.name;  // Completely remove 'name' property
          return updatedPermission;
     
      }).map(permission => permission.id.toString()),
    }));
    console.log(editedPermissionArray,"edited permissions")
    

    
    
  })}
}, [dispatch]);
console.log(editedPermissionArray,"permissions arraryr")
useEffect(() => {
    dispatch(getPermission())
      .then((action) => {
        const data = action.payload;
        console.log(data.data,"data");
        const transformedData = Object.keys(data.data).map(key => ({
          title: data.data[key][0].permission_type.name,
          permissions: data.data[key]
        }));
        
        console.log(transformedData,"transformed data");
        
        setPermissions(transformedData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching users:", error);
      });
}, [dispatch]);
console.log(permissions,"en permission")
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    //  validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        

        if (path!=='add-role') {
          // Dispatch editCategories action if customer exists
          editedPermissionArray.name=roleName;
           // @ts-ignore
          await dispatch(editRole({ id: roleId, data: editedPermissionArray })).then(
            (action) => {
              fetchData();
              if (action.type === "role/editRole/fulfilled") {
                openSnackbar({
                  open: true,
                  message: "Roles edited successfully",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                  variant: "alert",
                  alert: {
                    color: "success",
                  },
                } as SnackbarProps);
                setSubmitting(false);
                closeModal();
              }
            },
          );
        } else {
          // Dispatch create newCat action if no customer exists
          console.log(values.name,"values");
            console.log(permissionsArray,"arrays result");
            endResults.name=values.name;
            endResults.permissions=permissionsArray
          // editedPermissionAray.name=values.name
          console.log(endResults,"end results");
          
          await dispatch(addNewRoles(editedPermissionArray)).then((action) => {
            
            
            fetchData();
            if (action.type === "role/addNewRoles/fulfilled") {
              openSnackbar({
                open: true,
                message: "Roles edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }

        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps
  } = formik;

  



  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{path !=='add-role' ? "Edit Role" : "New Role"} </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
             
                
            
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-name">
                          Role Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-name"
                          placeholder="Enter Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          value={roleName}
                          onChange={(e)=>{
                            setroleName(e.target.value);

                            if (path!=='add-role') {
                            seteditedPermissionArray((prevState) => ({
                              ...prevState,  // Preserve the previous state
                              name: e.target.value // Remove the specified permission
                            }));
                            console.log(editedPermissionArray,"edited permission array");
                          }
                          }}
                          
                        />
                      </Stack>
                    </Grid>
                    <Stack className="main-resume-container permission-card" direction={"column"}>
                    {permissions.map((permission: any) => (
                    <MainCard style={{marginTop:"10px"}}  content={false}
              title={themeDirection==="ltr"? permission.title.en:permission.title.ar}
              sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }} >

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                     
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {permission.permissions.map((element)=>(
                           <FormGroup row>
                            
                            
                           <FormControlLabel
                             key={element.id} // Assuming 'key' is a unique identifier for each permission
                             control={
                               <Checkbox
                                checked={editedPermissionArray.permissions.filter((permission)=>permission==element.id).length>0?true:false}
                                
                                 onChange={(event) => {
                                   const isChecked = event.target.checked;
                                   console.log(event.target.checked,"event checked");
                                   console.log(editedPermissionArray,"edited permission");
                                   const editedArray= editedPermissionArray.permissions;
                                   console.log(editedArray,"edited array");
                                   if(isChecked===false){
                                    
                                    seteditedPermissionArray((prevState) => ({
                                      ...prevState,  // Preserve the previous state
                                      permissions: prevState.permissions.filter(permission => permission !== element.id.toString()), // Remove the specified permission
                                    }));
                                    console.log(editedPermissionArray,"edited permission array");
                                    

                                  }
                                   if(isChecked===true){
                                   console.log(element,"element")
                                
                                 
                                  
                                   seteditedPermissionArray((prevState) => ({
                                    ...prevState,               // Preserve the previous state
                                    permissions: [...prevState.permissions, element.id.toString()], // Add the new permission to the array
                                  }));
                                   console.log(editedPermissionArray,"edited permission")
                                   }
                                  
                                 }}
                               />
                             }
                             label={themeDirection==="ltr"? element.en_name:element.ar_name} // Assuming 'name' is the permission name
                             name={element.name}
                           
                           />
                       
                       </FormGroup>
                        ))}
                         
                        </Box>
                      </Stack>
                    </Grid>
                    </MainCard>
                      ))}
                    </Stack>
                 
            
          
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? <FormattedMessage id="edit" /> : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    
    </>
  );
};

export default FormRolesManagmentEdit;
