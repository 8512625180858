import { useEffect, useState } from "react";

// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";
import { FormattedMessage } from "react-intl";
// assets
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// types
import { SnackbarProps } from "types/snackbar";
import { CountryList } from "types/customer";
import { useAppDispatch } from "hooks";

import {
  ParentCountries,
  addNewCountry,
  editCountry,
  getAllCountries,
} from "store/actions/main/countries";
import { ParentCountry } from "model/country";
import { CircularProgress } from "@mui/material";
import { Switch } from "@mui/material";

// constant
const getInitialValues = (customer: CountryList | null) => {
  if (customer) {
    return {
      parent_name: customer.parent_name || "",
      name_trans: customer.name_trans.ar || "",
      is_active: customer.is_active || false,
      order: customer.order || "",
      name:customer.name || "",
      nationality:customer.nationality|| "",
      nationality_trans:customer.nationality_trans.ar|| "",
      
    };
  }
  else{
    return{
      parent_name: "",
      name: "",
      nationality: "",
      order: 0,
      is_active: false,
      name_trans:"",
      nationality_trans:""
    }
  }
 

};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCountryAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: CountryList | null;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<ParentCountry[]>([]);
  const [selectedParentCountry, setSelectedParentCountry] =
    useState<ParentCountry | null>(null);
console.log(customer,"customrer popup")
  const fetchDataCountry = (url?: string) => {
    dispatch(getAllCountries({pageNumber:1}))
      .then((action) => {
        const { data } = action.payload;
        const fetchedUserData: ParentCountry[] = data;
        const rowsWithKeys = fetchedUserData.map((user) => ({
          ...user,
          key: user.id,
        }));
        setCountries(rowsWithKeys);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchDataCountry();
  }, [dispatch]);
  useEffect(() => {
    setLoading(false);

    // Initialize selectedParentCountryId and selectedParentCountry if customer exists
    if (customer && customer.parent_name) {
      const selectedCountry = countries.find(
        (country) => country.name === customer.parent_name,
      );
      if (selectedCountry) {
        setSelectedParentCountry(selectedCountry);
        setSelectedParentCountryId(selectedCountry.name);
      }
    }
  }, [dispatch, id, customer, countries]);

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().max(255).required("English name is required"),
    name_trans: Yup.string().max(255).required("Arabic name is required"),
    nationality: Yup.string().max(255).required("Nationality in english is required"),
    nationality_trans: Yup.string().max(255).required("Nationality in arabic is required"),
    order: Yup.number().max(255).required("Order is required"),
  });


  const [selectedParentCountryId, setSelectedParentCountryId] = useState<
    string | null
  >(null);

  const handleChangeParentName = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const selectedCountry = countries.find(
      (country) => country.name === selectedValue,
    );
    setSelectedParentCountryId(event.target.value);
    setSelectedParentCountry(selectedCountry); // Update selected parent country state
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editCountry({
              countryId: customer.id || 0,
              data: {
                parent_id: selectedParentCountryId,
                name: values.name,
                name_trans: {
                ar:values.name_trans
                },
                nationality: values.nationality,
                nationality_trans:{
                  ar:values.nationality_trans
                },
                order: values.order,
                is_active: values.is_active,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "country/editCountry/fulfilled") {
              openSnackbar({
                open: true,
                message: "Country edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
         console.log(values,"values")
          await dispatch(
            addNewCountry({
                parent_id: selectedParentCountryId,
                name: values.name,
                name_trans:{
                  ar:values.name_trans
                },
                nationality: values.nationality,
                nationality_trans:{
                  ar:values.nationality_trans
                } ,
                order: values.order,
                is_active: values.is_active,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "country/addCountry/fulfilled") {
              openSnackbar({
                open: true,
                message: "Country added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { onBlur } = getFieldProps("parent_name");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
            
              {customer ?  <FormattedMessage id="edit-country-details" /> : <FormattedMessage id="add-new-country" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="english-name" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-english-name" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder="Enter english name"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="arabic-name" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-arabic-name-for-the-counter" />}>
                        
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                    fullWidth
                      id="customer-name"
                      name="name_trans"
                      {...getFieldProps("name_trans")}
                      placeholder="Enter Arabic Name"
                      error={Boolean(touched.name_trans && errors.name_trans)}
                      helperText={touched.name_trans && errors.name_trans}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="english-nationality" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-english-nationality" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder="Enter english  nationality"
                      {...getFieldProps("nationality")}
                      error={Boolean(touched.nationality && errors.nationality)}
                      helperText={touched.nationality && errors.nationality}
                    />
                  </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="arabic-nationality" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-arabic-nationality" />}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                    fullWidth
                      id="customer-name"
                      name="nationality_trans"
                      {...getFieldProps("nationality_trans")}
                      placeholder="Enter arabic nationality"
                      error={Boolean(touched.nationality_trans && errors.nationality_trans)}
                      helperText={touched.nationality_trans && errors.nationality_trans}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} mb={2}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-parent_name">
                        <FormattedMessage id="parent-name" /><span style={{ color: "grey" }}>{` (Optional)`}</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={<FormattedMessage id="add-parent-name" />}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-parent_name"
                          displayEmpty
                          value={
                            selectedParentCountry
                              ? selectedParentCountry.name
                              : customer?.parent_name
                          }
                          onChange={handleChangeParentName}
                          onBlur={onBlur}
                          input={
                            <OutlinedInput
                              id="select-column-hiding"
                              placeholder="Sort by"
                            />
                          }
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <Typography variant="subtitle1"></Typography>
                              );
                            }
                            const cat = countries.find(
                              (item) => item.name === selected,
                            );
                            return (
                              <Typography variant="subtitle2">
                                {cat ? cat.name : ""}
                              </Typography>
                            );
                          }}
                        >
                          {countries.map((column: any) => (
                            <MenuItem key={column.id} value={column.name}>
                              <ListItemText primary={column.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} sm={5}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-order"><FormattedMessage id="order" /><span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="type-order-number" />}>
                     
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type="number"
                      id="customer-order"
                      placeholder="Enter Order"
                      {...getFieldProps("order")}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                    <FormattedMessage id="Status" />
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="set-the-country-status" />}>
                        
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={formik.values.is_active}
                            {...getFieldProps("is_active")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("is_active", checked);
                            }}
                          />
                        }
                        label={formik.values.is_active ? "Active" : "Inactive"}
                      />
                    </FormControl>
                    {touched.is_active && errors.is_active && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.is_active}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : <FormattedMessage id="submit" />}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormCountryAdd;
