import {useEffect, useRef, useState } from "react";


// material-ui
import {
  Autocomplete,
  Box,
  Button,

  Divider,
 
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
} from "@mui/material";
import { DatePicker } from "antd";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import { openSnackbar } from "api/snackbar";
import MainCard from "components/MainCard";

// types
import { SnackbarProps } from "types/snackbar";
import phoneNumber from "hooks/data/phoneNumber";

import { useAppDispatch } from "hooks";

import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import {
  editJobSeekerProfile,
  getOneJobSeekerProfile,
} from "store/actions/main/cv/jobSeekerProfile/jobSeekerProfile";
import moment from "moment";

import ProfileTabs from "pages/extra-pages/resumes/profileTabs";

import { getAllTitles } from "store/actions/main/basicInfo/title";

// ==============================|| TAB - PERSONAL ||============================== //

const ProfileJobSeeker = () => {
  const maxDate = new Date();

  const pathname = window.location.pathname;

  // Split the pathname into segments using "/"
  const segments = pathname.split("/");

  // Extract the resume ID (second-to-last segment) and user ID (last segment)

  const userId = segments[segments.length - 1];

  maxDate.setFullYear(maxDate.getFullYear() - 18);

  const [nationality, setNationality] = useState<any>(false);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [selectedTitleID, setSelectedTitleID] = useState("");
// @ts-ignore
  const [activeSection, setActiveSection] = useState(0); // State variable for active section index

  useEffect(() => {
    const navButtons = document.querySelectorAll(".nav-button");
    navButtons.forEach((button) => {
      button.classList.remove("active");
    });
    const activeButton = document.querySelector(`#nav-button-${activeSection}`);
    if (activeButton) {
      activeButton.classList.add("active");
    }
  }, [activeSection]);

  const dispatch = useAppDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [country, setCountry] = useState<any>();

  const [childCities, setChildCities] = useState([]); // Step 3: State to store child cities

  // This will give you the value "2" from the URL

  const [profileData, setProfileData] = useState({
    resume_id: 1,
    account_photo: null,
    title_id: "",
    birthdate: "",
    city: "",
    confidetial: 0,
    countryCode: "+963",
    country: "",
    driving_license: "",
    driving_license_date: "",
    gender: "",
    full_name: "",
    nationality: "",
    marital_status: "",
    military_service: "",
    military_service_date: "",
    street_name: "",
    phone_number: "",
    title:""
  });

  const [code, setCode] = useState("");
  // Ensure `dispatch` and `id` are in the dependency array
  const [selectedCountryName, setSelectedCountryName] = useState("");

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );
    console.log("Selected Country Object:", selectedCountryObject); // Add this line for debugging
    console.log("Selected Country ID:", selectedCountryId); // Add this line for debugging

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
          setNationality(data[0].nationality);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchTitleData = () => {
    dispatch(getAllTitles({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
console.log(data,"title data")
        // Extract user names
        setSelectedTitle(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  // Dispatch parent countries action when the component mounts
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
  }, [dispatch]);

  const [isMilitaryServiceDisabled, setIsMilitaryServiceDisabled] =
    useState(false);
    // @ts-ignore
  const [isDrivingDisabled, setIsDrivingDisabled] = useState(false);
  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue = type === "checkbox" ? checked : value;

    // Update profileData state with the new value
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: newValue,
    }));

    // Toggle military service field based on selected gender
    if (name === "gender" && value === "FEMALE") {
      setIsMilitaryServiceDisabled(true);
    } else if (name === "gender" && value === "MALE") {
      setIsMilitaryServiceDisabled(false);
    }
    if (name === "driving_license" && value === "NONE") {
      setIsDrivingDisabled(true);
    } else {
      setIsDrivingDisabled(false);
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : ""; // Format the date as per your requirement
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      birthdate: formattedDate,
    }));
  };
 
  const handleMilitaryDateChange = (date) => {
    const formatteMilitarydDate = date ? date.format("YYYY-MM-DD") : ""; // Format the date as per your requirement
    setProfileData((prevProfileData) => ({
      ...prevProfileData,

      military_service_date: formatteMilitarydDate,
    }));
  };

  // Function to handle city change
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  const fileInputRef = useRef(null);
  // Function to handle file change event
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file was selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        setProfileData({ ...profileData, account_photo: file });
        // Clear any previous file error
      } else {
        setProfileData({ ...profileData, account_photo: null });
        // Set file error message
      }
    }
  };

  // Dispatch child cities action when the selected country changes
  const validationSchema = Yup.object().shape({
    
    marital_status: Yup.string().required("Marital Status is required")
,
    full_name_english: Yup.string().required("English full name is required")
        ,
    birthdate:
   Yup.date().required("Birthdate is required"),
    gender:Yup.string().required("Gender is required")
       ,

    phone_number:Yup.number().required("Phone number is required"),
    // Add more fields as needed
    // Add more fields as needed

    // Add more fields as needed
  });
  const handleTitleChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedTitleID(selectedId); // Set the selected ID to state
    const selectedItem = selectedTitle.find((item) => item.id === selectedId);
    if (selectedItem) {
     
    }
  };
  const fetchGetOne = () => {
    dispatch(getOneJobSeekerProfile({ id: userId })).then((action) => {
      const data = action.payload["result: "];

      setProfileData(data);
      setSelectedTitleID(profileData?.title_id);
    });
  };

  useEffect(() => {
    fetchGetOne();
    fetchTitleData();
  }, [dispatch]);
  return (
    <>
      <section id="section-0">
        <Stack direction={"row"}>
          <Grid style={{ height: "100px", marginRight: "20px", width: "100%" }}>
          {/*  @ts-ignore */}
            <ProfileTabs />
          </Grid>
          <Stack direction={"column"}>
            <MainCard
              content={false}
              title="Personal Information"
              sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
            >
              <Formik
                initialValues={{
                  resume_id: 1,
                  title: profileData?.title_id || "",
                  countryCode: "",
                 
                  full_name_english: profileData?.full_name || "",
                  birthdate: profileData?.birthdate || "",
                  gender: profileData?.gender || "",
                  nationality: profileData?.nationality || "",
                  marital_status: profileData?.marital_status || "",
                  military_service: profileData?.military_service || "",
                  military_service_date:
                    profileData?.military_service_date || "",
                  confidetial: profileData?.confidetial || "",
                  driving_license: profileData?.driving_license || "",
                  driving_license_date: profileData?.driving_license_date || "",
                  account_photo: profileData.account_photo,
                  country: profileData?.country || "",
                  city: profileData?.city || "",
                  street_name: profileData?.street_name || "",
                  phone_number: profileData?.phone_number || "",
                  submit: null,
                }}
                validationSchema={validationSchema} // Pass the validation schema to Formik
                onSubmit={(values, { setSubmitting }) => {
                  // Check if account_photo is an image
                  if (profileData.account_photo) {
                    // Prepare FormData object

                    const formData = new FormData();

                    formData.append("title", selectedTitleID);
                   
                    formData.append(
                      "full_name_english",
                      profileData.full_name,
                    );
                    formData.append("city", selectedCityName);
                    formData.append("birthdate", profileData.birthdate);
                    formData.append("gender", profileData.gender);
                    formData.append("nationality", nationality);
                    formData.append(
                      "marital_status",
                      profileData.marital_status,
                    );
                    formData.append(
                      "military_service",
                      profileData.military_service,
                    );
                    formData.append(
                      "military_service_date",
                      profileData.military_service_date,
                    );
                    formData.append(
                      "driving_license",
                      profileData.driving_license,
                    );
                    formData.append(
                      "driving_license_date",
                      profileData.driving_license_date,
                    );
                    //   formData.append("confidetial", profileData.confidetial);
                    // Assuming profileData.account_photo is a File object or a Blob
                    formData.append("account photo", profileData.account_photo);
                    formData.append("country", selectedCountryName);
                    formData.append("street_name", profileData.street_name);
                    const countryCode = Object.values(code)[0]; // Assuming code is an object with only one key-value pair

                    // Concatenate country code and phone number
                    const phoneNumberWithCode = `${countryCode}-${profileData.phone_number}`;
                    formData.append("phone_number", phoneNumberWithCode);

                    dispatch(
                      editJobSeekerProfile({ id: userId, data: formData }),
                    ).then((action) => {
                      if (
                        action.type ===
                        "jobSeeker/editJobSeekerProfile/fulfilled"
                      ) {
                        openSnackbar({
                          open: true,
                          message: "profile updated successfully",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "alert",
                          alert: {
                            color: "success",
                          },
                        } as SnackbarProps);
                        setSubmitting(false);
                      }
                    });

                    // Send FormData with Axios
                    // Set file error message
                  } // Your form submission logic here
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <>
                    <form noValidate onSubmit={handleSubmit}>
                      <Divider />
                      <Box sx={{ p: 2.5 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-name_en"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Title *
                            </InputLabel>
                            <FormControl fullWidth>
                              <Select
                                id="title"
                                value={selectedTitleID} // Set the selected ID as the value of the Select component
                                onChange={handleTitleChange} // Handle the change event
                              >
                                {selectedTitle.map((user) => (
                                  <MenuItem key={user.id} value={user.id}>
                                    {user.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-name_en"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Full Name *
                            </InputLabel>
                            <TextField
                              fullWidth
                              id={"full_name_english"}
                              value={profileData?.full_name|| ""}
                              name={"full_name_english"}
                              onBlur={handleBlur}
                              onChange={handleChange} // U
                              placeholder={"Full Name English"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-name_en"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Date of Birth *
                            </InputLabel>
                            <DatePicker
                              value={
                                profileData.birthdate
                                  ? moment(profileData.birthdate)
                                  : null
                              }
                              onChange={handleDateChange}
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "4px",
                                borderColor: "lightgray",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-name_en"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Nationality
                            </InputLabel>
                            <FormControl fullWidth>
                              <Select
                                name="nationality"
                                value={nationality}
                                onChange={(event:any) =>
                                  setNationality(event.target.value)
                                }
                                onBlur={handleBlur}
                                inputProps={{
                                  name: "nationality",
                                  id: "nationality",
                                }}
                              >
                                {country &&
                                  country.map((country: any) => (
                                    <MenuItem
                                      key={country.id}
                                      value={country.nationality}
                                    >
                                      {country.nationality}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-marital_status"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Marital Status
                            </InputLabel>
                            <FormControl fullWidth>
                              <Select
                                value={profileData?.marital_status }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="marital_status"
                              >
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Single">Single</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-confidetial"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Confidetial
                            </InputLabel>
                            <FormControl component="fieldset">
                              <RadioGroup
                                // Check if the field has been touched and has an error

                                aria-label="confidetial"
                                name="confidetial"
                                value={profileData.confidetial}
                                onChange={handleChange}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-gender"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Gender
                            </InputLabel>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="gender"
                                value={profileData.gender}
                                onChange={handleChange}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="MALE"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="FEMALE"
                                  control={<Radio />}
                                  label="Female"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-military_service"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Military Service
                            </InputLabel>
                            <FormControl fullWidth>
                              <Select
                                value={profileData.military_service}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={isMilitaryServiceDisabled}
                                name="military_service"
                              >
                                <MenuItem value="NOT_FINISHED">
                                  Not Finished
                                </MenuItem>
                                <MenuItem value="FINISHED">Finished</MenuItem>
                                <MenuItem value="EXEMPTED">Exempted</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-gender"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Military Service Date
                            </InputLabel>
                            <DatePicker
                              disabled={isMilitaryServiceDisabled}
                              value={
                                profileData.military_service_date
                                  ? moment(profileData.military_service_date)
                                  : null
                              }
                              onChange={handleMilitaryDateChange}
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "4px",
                                borderColor: "lightgray",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-gender"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Driving License
                            </InputLabel>
                            <FormControl fullWidth>
                              <Select
                                name="driving_license"
                                value={profileData.driving_license}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.driving_license &&
                                  Boolean(errors.driving_license)
                                } // Check if the field has been touched and has an error
                                // helperText={
                                //   touched.driving_license &&
                                //   errors.driving_license
                                // } //
                              >
                                <MenuItem value="NONE">None</MenuItem>
                                <MenuItem value="TYPE_A">TYPE A</MenuItem>
                                <MenuItem value="TYPE_B">TYPE B</MenuItem>
                                <MenuItem value="TYPE_C">TYPE C</MenuItem>
                                <MenuItem value="TYPE_D">TYPE D</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-gender"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Driving License Date
                            </InputLabel>
                            <DatePicker
                              disabled={isMilitaryServiceDisabled}
                              value={
                                profileData.military_service_date
                                  ? moment(profileData.military_service_date)
                                  : null
                              }
                              onChange={handleMilitaryDateChange}
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "4px",
                                borderColor: "lightgray",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                              <Stack direction={"row"} display={"flex"}>
                                <InputLabel htmlFor="country">
                                  Country
                                </InputLabel>
                                <InputLabel
                                  style={{ marginLeft: "130px" }}
                                  htmlFor="city"
                                >
                                  City
                                </InputLabel>
                              </Stack>
                              <Stack direction={"row"}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  // alignItems="center"
                                >
                                  <FormControl fullWidth>
                                    <Select
                                      style={{ width: "140px" }}
                                      value={selectedCountry}
                                      onChange={handleCountryChange}
                                      //  onBlur={handleBlur}
                                      inputProps={{
                                        name: "country",
                                        id: "country",
                                      }}
                                    >
                                      {country &&
                                      // @ts-ignore
                                        country.map((country: any) => (
                                          <MenuItem
                                            key={country.id}
                                            value={country.id}
                                          >
                                            {country.name}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </Stack>

                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  spacing={2}
                                >
                                  <FormControl fullWidth>
                                    <Select
                                      style={{
                                        marginLeft: "20px",
                                        width: "140px",
                                      }}
                                      labelId="city-label"
                                      id="city"
                                      value={selectedCity}
                                      onChange={handleCityChange}
                                      // onBlur={handleBlur}
                                      name="city"
                                    >
                                      {childCities.map((city) => (
                                        <MenuItem key={city.id} value={city.id}>
                                          {city.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Stack>
                              </Stack>
                            </Stack>
                          </Grid>
                     
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-name_en"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Mobile Number *
                            </InputLabel>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                            >
                              <Autocomplete
                                style={{ width: "200px" }}
                                id="personal-country-code"
                                options={phoneNumber}
                                value={{ dialCode: "+963" }}
                                autoHighlight
                                getOptionLabel={(option) => option.dialCode}
                                onChange={(event, newValue:any) => {
                                  // newValue here will be the selected option
                                  setCode(newValue); // You can use newValue to get the selected value
                                }}
                                renderOption={(props, option) => (
                                  <MenuItem
                                    component="li"
                                    sx={{ typography: "body2" }}
                                    {...props}
                                  >
                                    ({option.dialCode})
                                  </MenuItem>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name="countryCode"
                                    placeholder=""
                                    error={Boolean(
                                      touched.countryCode && errors.countryCode,
                                    )}
                                    helperText={
                                      touched.countryCode && errors.countryCode
                                    }
                                  />
                                )}
                              />

                              <TextField
                                fullWidth
                                id="customer-mobile_number"
                                placeholder="999 999 999"
                                value={profileData.phone_number}
                                name="phone_number" // Add the name attribute
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{ maxLength: 10 }} // Add the onChange handler
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              htmlFor="personal-name_en"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              Account Photo
                            </InputLabel>
                            <Stack direction={"row"}>
                              <input
                                type="file"
                                accept="image/*" // Allow only image files
                                ref={fileInputRef} // Attach the reference to the file input
                                // style={{ display: "none" }} // Hide the input element
                                onChange={handleFileChange}
                                // Handle file change event
                              />
                              <Button
                                onClick={() => fileInputRef.current.click()} // Trigger file input click on button click
                              >
                                Upload Photo
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box sx={{ p: 2.5 }}>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                          sx={{ mt: 2.5 }}
                        >
                          <Button variant="outlined" color="secondary">
                            Cancel
                          </Button>
                          <Button type="submit" variant="contained">
                            Edit
                          </Button>
                        </Stack>
                      </Box>
                    </form>
                  </>
                )}
              </Formik>
            </MainCard>
          </Stack>
        </Stack>
      </section>
    </>
  );
};

export default ProfileJobSeeker;
