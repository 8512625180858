import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import DashboardLayout from "layout/Dashboard";
import PagesLayout from "layout/Pages";
import SimpleLayout from "layout/Simple";
// types
import { SimpleLayoutType } from "types/config";
import DashboardDefault from "pages/extra-pages/pages/dashboard";
import Users from "pages/extra-pages/pages/users/user";
import Countries from "pages/extra-pages/pages/countries/country";
import Profile from "pages/extra-pages/resumes/Profile";
import CustomerListPage from "pages/extra-pages/pages/categories/categories";
import CompanyList from "pages/extra-pages/pages/companies/CompanyList";
import Vacancies from "pages/extra-pages/pages/packages/vacaciensPackages/Vacancies";
import ResumeList from "pages/extra-pages/pages/packages/resumesPackages/Resumes";
import Roles from "pages/extra-pages/pages/roles/roles";
import VacanciesSub from "pages/extra-pages/pages/subscriptions/vacancySubscription/VacanciesSubscription";
import ResumeSub from "pages/extra-pages/pages/subscriptions/resumesSubscription/ResumeSubscription";
import VacanciesOrder from "pages/extra-pages/pages/order/vacancyOrder/VacanciesOders";
import ResumesOrder from "pages/extra-pages/pages/order/resumesOrder/ResumeOrders";
import Banners from "pages/extra-pages/pages/ads/banners/banner";
import BestPlace from "pages/extra-pages/pages/ads/bestPlace/Bestplace";
import MajorCompany from "pages/extra-pages/pages/ads/majorCompany/MajorCompany";
import JobTypes from "pages/extra-pages/pages/basicInfo/jobTybes/jobTypes";
import JobAva from "pages/extra-pages/pages/basicInfo/jobAva/jobAva";
import Degree from "pages/extra-pages/pages/basicInfo/degree/degree";
import Educational from "pages/extra-pages/pages/basicInfo/educational/Educational";
import Language from "pages/extra-pages/pages/basicInfo/languages/Language";
import Skills from "pages/extra-pages/pages/basicInfo/skills/Skills";
import Payment from "pages/extra-pages/pages/payment/Payment";
import Resume from "pages/extra-pages/resumes/basicInfo/resume";
import Question from "pages/extra-pages/pages/basicInfo/Question/question";
import ActiveResume from "pages/extra-pages/resumes/active&inactive/active";
import InactiveResume from "pages/extra-pages/resumes/active&inactive/inactive";
import BannerPlace from "pages/extra-pages/pages/basicInfo/bannerPlace/bannerPlace";
import CompanyProfile from "pages/extra-pages/company/companyProfile";
import CompanyProfileList from "pages/extra-pages/company/companyTable/CompanyProfile";
import YESClientProfileList from "pages/extra-pages/company/yesCompany";
import NoClientProfileList from "pages/extra-pages/company/NoClient";
import UndefinedClientProfileList from "pages/extra-pages/company/undefinedCompany";
import FreeClientProfileList from "pages/extra-pages/company/freeCompany";
import JobSeekerProfileList from "pages/extra-pages/jobseeker/jobseekerTable/JobSeekerProfile";
import CompanyLoggedTodList from "pages/extra-pages/company/companyLoggedToday/CompanyLoggedToday";
import CompanyLoggedWeekList from "pages/extra-pages/company/companyLoggedWeek/CompanyLoggedWeek";
import CommentActionsList from "pages/extra-pages/pages/basicInfo/commentAction/commentActions";
import ReportList from "pages/extra-pages/pages/basicInfo/report/Report";
import TitleList from "pages/extra-pages/pages/basicInfo/title/Title";
import CurrencyList from "pages/extra-pages/pages/basicInfo/currency/Currency";
import BankList from "pages/extra-pages/pages/basicInfo/bank/Bank";
import ReplyAppList from "pages/extra-pages/company/replyApp/ReplyApp";
import EditCompanyProfile from "pages/extra-pages/company/EditCompanyProfile";
import ProfileJobSeeker from "pages/extra-pages/jobseeker/ProfileJobSeeker/profileJobSeeker";
import CompanyReplyList from "pages/extra-pages/company/companyReplies/CompanyReply";
import CompanyReportList from "pages/extra-pages/company/companyReports/CompanyReport";
import CompanySuggestedList from "pages/extra-pages/company/suggestedCompany/CompanySuggested";
import CompanyDescriptionList from "pages/extra-pages/company/descriptionCompany/CompanyDescription";
import CompanyCommentList from "pages/extra-pages/company/commentsOfCompReply/CompanyComment";
import VacanciesList from "pages/extra-pages/pages/vacancy/Vacancies";
import MailListPage from "pages/extra-pages/pages/mail-templates/mails";
import AdminResumeNoteType from "pages/extra-pages/pages/admin-resume-note-type/adminResumeNoteType";
import FormVacancyManagmentEdit from "pages/extra-pages/pages/vacancy/EditVacancy";
import StaticPages from "pages/extra-pages/static-pages/index";
import FormPageManagmentEdit from "pages/extra-pages/static-pages/editPage";
import FormRolesManagmentEdit from "pages/extra-pages/pages/roles/rolesEdit";
import FormCustomerAdd from "pages/extra-pages/pages/mail-templates/FormCustomerAdd";
import Translations from "pages/extra-pages/pages/translations/translations";


const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/500")),
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction")),
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon")),
);

const AppContactUS = Loadable(lazy(() => import("pages/contact-us")));
// render - sample page
//const SamplePage = Loadable(lazy(() => import('pages/extra-pages/pages/extra-pages/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "dashboard",
          element: <DashboardDefault />,
        },
        {
          path: "users",
          element: <Users />,
        },
        { path: "companies", element: <CompanyList /> },
        { path: "roles", element: <Roles /> },
        { path: "translations", element: <Translations /> },
        { path: "categories", element: <CustomerListPage /> },
        { path: "countries", element: <Countries /> },
        { path: "resumes", element: <Resume /> },
        { path: "mail-templates", element: <MailListPage /> },
        { path: "mail-templates/edit-mail-template/:mailId", element: <FormCustomerAdd /> },
        { path: "admin-resume-note-type", element: <AdminResumeNoteType /> },



        {
          path: "resumes/edit-resume/:resumeId",
          element: <Profile />,
        },
        { path: "resumes/add-resume/:userId", element: <Profile /> },
        { path: "vacancy/edit-vacancy/:vacancyId", element: <FormVacancyManagmentEdit/> },
        { path: "vacancy/add-vacancy/:vacancyId", element: <FormVacancyManagmentEdit/> },
        { path: "static-pages/edit-page/:pageId", element: <FormPageManagmentEdit/> },
        { path: "static-pages/add-page/:pageId", element: <FormPageManagmentEdit/> },
        {
          path: "list-jobseekers",
          element: <JobSeekerProfileList />,
        },
        {
          path: "active-resume",
          element: <ActiveResume />,
        },
        {
          path: "unactive-resume",
          element: <InactiveResume />,
        },
        { path: "resume/:resumeId", element: <Profile /> },
        { path: "banners", element: <Banners /> },
        { path: "bannerplace", element: <BannerPlace /> },

        { path: "best-place", element: <BestPlace /> },
        { path: "major-company", element: <MajorCompany /> },
        { path: "jobtypes", element: <JobTypes /> },
        { path: "educational", element: <Educational /> },
        { path: "payment", element: <Payment /> },
        { path: "jobavailabilities", element: <JobAva /> },
        { path: "degree", element: <Degree /> },
        { path: "language", element: <Language /> },
        { path: "skills", element: <Skills /> },
        { path: "vacancies-packages", element: <Vacancies /> },
        { path: "subscription-jobs", element: <VacanciesSub /> },
        { path: "subscription-resumes", element: <ResumeSub /> },
        { path: "orders-resumes", element: <ResumesOrder /> },
        { path: "resumes-packages", element: <ResumeList /> },
        { path: "orders-jobs", element: <VacanciesOrder /> },
        { path: "question", element: <Question /> },
        { path: "resumes/add-resume", element: <Profile /> },
        { path: "currency", element: <CurrencyList /> },
        { path: "titles", element: <TitleList /> },
        { path: "bank", element: <BankList /> },

        { path: "course", element: <DashboardDefault /> },
        { path: "coursecategories", element: <DashboardDefault /> },
        { path: "educationalcompanies", element: <DashboardDefault /> },
        { path: "coursecertificates", element: <DashboardDefault /> },
        { path: "coursedays", element: <DashboardDefault /> },
        { path: "coursepriods", element: <DashboardDefault /> },
        { path: "addedtutorials", element: <DashboardDefault /> },
        { path: "requestedtutorials", element: <DashboardDefault /> },
        { path: "tutorialpackages", element: <DashboardDefault /> },
        { path: "tutorialcategories", element: <DashboardDefault /> },
        { path: "tutorialtargetedsegments", element: <DashboardDefault /> },
        {
          path: "suggestededucationalinstitution",
          element: <DashboardDefault />,
        },
        { path: "educationalexperiencelist", element: <DashboardDefault /> },

        { path: "list-of-companies", element: <CompanyProfileList /> },
        { path: "list-of-vacancies", element: <VacanciesList /> },
        { path: "client-companies-list", element: <YESClientProfileList /> },
        { path: "not-client-companies-list", element: <NoClientProfileList /> },
        {
          path: "undefined-companies-list",
          element: <UndefinedClientProfileList />,
        },
        { path: "free-companies-list", element: <FreeClientProfileList /> },
        { path: "companies-search", element: <DashboardDefault /> },
        { path: "companies-logged-today", element: <CompanyLoggedTodList /> },
        {
          path: "companies-logged-through-week",
          element: <CompanyLoggedWeekList />,
        },

        { path: "suggested-companies", element: <CompanySuggestedList /> },
        { path: "service-persons", element: <DashboardDefault /> },
        {
          path: "companies-description",
          element: <CompanyDescriptionList />,
        },
        { path: "companies-replies", element: <CompanyReplyList /> },
        { path: "companies-reports", element: <CompanyReportList /> },
        {
          path: "comments-of-company-profile",
          element: <CompanyCommentList />,
        },
        { path: "reply-to-applicant", element: <ReplyAppList /> },

        {
          path: "company-list/add-profile/:profileId",
          element: <CompanyProfile />,
        },
        {
          path: "company-list/edit-profile/:profileId/:userId",
          element: <EditCompanyProfile />,
        },
        {
          path: "companies-reports/edit-profile/:profileId",
          element: <EditCompanyProfile />,
        },
        {
          path: "static-pages/",
          element: <StaticPages />,
        },
        {
          path: "edit-role/:roleId",
          element: <FormRolesManagmentEdit/>,
        },
        {
          path: "add-role",
          element: <FormRolesManagmentEdit/>,
        },
        
       
        {
          path: "companies-replies/edit-resume/:resumeId",
          element: <Profile />,
        },
        {
          path: "companies-replies/reply-to-applicant",
          element: <ReplyAppList />,
        },
        {
          path: "companies-reports/edit-resume/:resumeId",
          element: <Profile />,
        },
        {
          path: "companies-description/edit-resume/:resumeId",
          element: <Profile />,
        },
        {
          path: "companies-reports/report-candidate",
          element: <ReportList />,
        },

        {
          path: "list-jobseekers/add-profile/:profileId",
          element: <ProfileJobSeeker />,
        },
        {
          path: "list-jobseekers/edit-profile/:profileId",
          element: <ProfileJobSeeker />,
        },
        {
          path: "comment-action",
          element: <CommentActionsList />,
        },
        {
          path: "report-candidate",
          element: <ReportList />,
        },
      ],
    },

    {
      path: "/",
      element: <SimpleLayout layout={SimpleLayoutType.SIMPLE} />,
      children: [
        {
          path: "contact-us",
          element: <AppContactUS />,
        },
      ],
    },
    {
      path: "/maintenance",
      element: <PagesLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
  ],
};

export default MainRoutes;
