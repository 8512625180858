// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Title } from "model/basicInfo";
import {
  addCurrency,
  editCurrency,
} from "store/actions/main/basicInfo/currency";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";

// constant
const getInitialValues = (customer: Title | null) => {
  if (customer) {
    return {
      name: customer.name || "",
      name_trans:{
        ar:customer.name_trans || ""
      },
    };
  } else {
    return {
      name: "",
      name_trans:{
        ar:""
      },
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCurrencyAdd = ({
  customer,
  closeModal,
  fetchData,
  loading,
}: {
  loading: any;
  setLoading?: any;
  fetchData?: any;
  id?: number;
  customer: Title | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().required("English Name is required"),
    name_trans: Yup.string().required("Arabic Name is required"),
  });

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editCurrency({
              id: customer.id || 0,
              data: {
                name: values.name,
                name_trans: {
                ar:values.name_trans
                }
            
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "basicInfo/editCurrency/fulfilled") {
              openSnackbar({
                open: true,
                message: "Currency edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addCurrency({
            name: values.name,
            name_trans: {
            ar:values.name_trans
            }
        
          })).then((action) => {
            fetchData();
            if (action.type === "basicInfo/addCurrency/fulfilled") {
              openSnackbar({
                open: true,
                message: "Currencys added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
         <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? <FormattedMessage id="edit-currency-details" /> : <FormattedMessage id="add-new-currency" />}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="english-name" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={<FormattedMessage id="enter-name-for-the-currency" />}>
                       
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder="Enter english name"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name"><FormattedMessage id="arabic-name" /> <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={ <FormattedMessage id="enter-arabic-name-for-the-currency" />}>
                       
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder="Enter arabic name"
                      {...getFieldProps("name_trans")}
                      error={Boolean(touched.name_trans && errors.name_trans)}
                   
                    />
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> :<FormattedMessage id="submit" />}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormCurrencyAdd;
