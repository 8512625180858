import {
  Box,
  Button,

  DialogTitle,

  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets

// types
import { SnackbarProps } from "types/snackbar";
import { CustomerList } from "types/customer";
import { useAppDispatch } from "hooks";
import {
  addNewMail,
  editMail,
  getOneMail,
} from "store/actions/main/mail";
import { CircularProgress } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Mail } from "model/basicInfo";

import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";




// constant
const getInitialValues = (customer: Mail | null) => {
  const newCustomer = {
    user_type: '',
    mail_type: '',
    en_title: '',
    ar_title: '',
    en_text: '',
    ar_text: ''
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};
// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCustomerAdd = ({
  customer,
  
  loading,
  fetchData,

}: {
  loading?: any;


  fetchData?: any;
  customer?: CustomerList | any;
 
}) => {

  const pathname = window.location.pathname;
  const segments = pathname.split("/");
  const mailId = segments[segments?.length - 1];
  const dispatch = useAppDispatch();
  const [mailData, setmailData] = useState<any>({
   body: "",
language: "",
mail_type: "",
status: false,
title: "",
user_type: ""});
  useEffect(() => {
    if(mailId!=="0"){
    dispatch(getOneMail({mailId:mailId})).then((action)=>{
      console.log(action.payload.data,"data vacancy");
      const  data  = action.payload["data: "];
      console.log(data,"mail one");
      setmailData(action.payload.data)
           
          })}
  }, [dispatch]);
  const CustomerSchema = Yup.object().shape({
    user_type: Yup.string().required("User type name is required"),
    mail_type: Yup.string().required("Mail type is required"),
    en_title: Yup.string().required("English title is required"),
    ar_title: Yup.string().required("Arabic title is required"),
    en_text: Yup.string().required("English text is required"),
    ar_text: Yup.string().required("Arabic text is required"),

  });

const [Value, setValue] = useState<any>();
  const formik = useFormik({
    initialValues: getInitialValues(mailData!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editMail({
              // @ts-ignore
              mailId: mailId,
              data: {
                user_type: values.user_type,
                mail_type: values.mail_type,
                en_title: values.en_title,
                ar_title: values.ar_title,
                en_text: values.en_text,
                ar_text: Value
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "mail/editMail/fulfilled") {
              openSnackbar({
                open: true,
                message: "Mail template edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
          
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewMail({
              user_type: values.user_type,
              mail_type: values.mail_type,
              en_title: values.en_title,
              ar_title: values.ar_title,
              en_text: values.en_text,
              ar_text: values.ar_text
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "mail/addMail/fulfilled") {
              openSnackbar({
                open: true,
                message: "Mail template added successfully!",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
          
            }
          });
        }
    
      } catch (error) {
        console.error(error);
      }
    },
  });
  const save = (e: any) => {
    console.log(e,"rich text data");
    setValue(e)
  };
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit mail template details" : "Add new mail template"}
            </DialogTitle>
       
         
              <Grid container>
                <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-type"> User Type <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the user type of the mail template'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-type"
                          displayEmpty
                          value={mailData.user_type}
                          {...getFieldProps("type")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            const selectedValue = event.target.value;
                            setFieldValue("user_type", selectedValue);
                          }}>
                          <MenuItem value={"JOBSEEKER"}>JOBSEEKER</MenuItem>
                          <MenuItem value={"COMPANY"}>COMPANY</MenuItem>
                        </Select>
                      </Stack>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} sm={5} mb={1}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="customer-type"> Mail Type <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                              <Tooltip title={'Set the mail type of the mail template'}>
                                <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                              </Tooltip>
                            </span>
                          </InputLabel>
                          <Select
                            id="customer-type"
                            value={mailData.mail_type}
                            displayEmpty
                            {...getFieldProps("type")}
                            onChange={(event: SelectChangeEvent<boolean>) => {
                              const selectedValue = event.target.value;
                              setFieldValue("mail_type", selectedValue);
                            }}>
                            <MenuItem value={"PROFILE"}>PROFILE</MenuItem>
                            <MenuItem value={"VACANCY_RESUME"}>VACANCY RESUME</MenuItem>
                          </Select>
                        </Stack>
                </Grid>
                <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-en_title">
                          English title  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the english tilte of the mail template'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-en_title"
                          placeholder="English title"
                          {...getFieldProps("en_title")}
                          error={Boolean(
                            touched.en_title && errors.en_title,
                          )}
                          helperText={
                            touched.en_title && errors.en_title
                          }
                        />
                      </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} sm={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-ar_title">Arabic title  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the arabic tilte of the mail template'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-ar_title"
                      value={mailData.title}
                      placeholder="arabic title"
                      {...getFieldProps("ar_title")}
                      error={Boolean(touched.ar_title && errors.ar_title)}
                      helperText={touched.ar_title && errors.ar_title}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={11} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-note">
                      <FormattedMessage id="english-text" />  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the english text of the mail template'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    {/* <TextArea
                      id="customer-note"
                      placeholder="Note"
                      {...getFieldProps("en_text")}
                    /> */}
                     <ReactQuill  style={{height:"150px"}} theme="snow" value={mailData.body}  onChange={save} />
                  </Stack>
                  {touched.en_text && errors.en_text && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{ pl: 1.75 }}>
                      {errors.en_text}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={11} mb={1} style={{marginTop:"40px"}} >
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-note">
                      Arabic Text  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the arabic text of the mail template'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <ReactQuill  style={{height:"150px"}} theme="snow"  onChange={save} />
                  </Stack>
                  {touched.ar_text && errors.ar_text && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{ pl: 1.75 }}>
                      {errors.ar_text}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            
         
        
              <Grid container >
                  <Stack direction="row" spacing={2} >
                 
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    
                  
                  </Stack>
              </Grid>
         
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormCustomerAdd;
