import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from "@mui/material";

//translation
import DeleteIcon from "../../../../assets/icons/cancelOrder.svg";

import { useAppDispatch } from "hooks";

import { FormattedMessage } from "react-intl";

import { SnackbarProps } from "types/snackbar";
import { openSnackbar } from "api/snackbar";

import { deleteAnswer } from "store/actions/main/basicInfo/answer";
//import { deleteUser } from 'store/reducers/users'
const DeletQuestion = ({
  open,
  setOpen,
  id,
  fetchDataQues,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: number;
  fetchDataQues?: any;
}) => {
  const dispatch = useAppDispatch();
  const handleDelete = () => {
    dispatch(deleteAnswer({ id: id })).then((action) => {
      if (action.type === "basicInfo/deleteAnswer/fulfilled") {
        openSnackbar({
          open: true,
          message: "Answer deleted successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);

        setOpen(false);

        fetchDataQues();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "10vh",
            width: { md: "450px" },

            bottom: "22%",
          },
        }}
      >
        <Grid sx={{ display: "flex", alignItems: "center", marginTop: "50px" }}>
          <img
            src={DeleteIcon}
            alt="cancelOrder"
            style={{ width: "40px", height: "40px" }}
          />
        </Grid>

        <DialogContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold" fontSize="20px">
              <FormattedMessage id="course-message" />
            </Typography>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            width: "90%",
          }}
        >
          <Button
            onClick={handleDelete}
            sx={{
              background: "#1890ff",
              fontSize: "18px",
              fontWeight: 800,
              minWidth: "180px",
              minHeight: "50px",

              color: "white",
            }}
          >
            <FormattedMessage id="delete-user" />
          </Button>

          <Button
            onClick={() => setOpen(false)}
            sx={{
              border: "1px solid #3B3486",
              borderColor: "#1890ff",
              fontSize: "20px",
              fontWeight: 800,
              // minHeight: '3px',
              minWidth: "180px",
              color: "#1890ff",
              paddingLeft: 0, // Set padding to 0 or adjust as needed
            }}
          >
            <FormattedMessage id="back" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeletQuestion;
